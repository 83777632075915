.requirement, .schedule {
  background: linear-gradient(180.52deg, rgba(11, 49, 67, 0.95) 0.84%, rgba(45, 55, 80, 0.95) 99.94%);
  padding: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 2%;
}

.header {
  display: flex;
  align-items: center;
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(217, 217, 217, 0.1);
  margin: 0 -16px;
}

.header span {
  font-family: 'Museo Sans';
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.22px;
  text-align: left;
  color: #FAFAFA;
  margin-left: 24px;
}

.infoIcon {
  margin-left: 5px;
  cursor: pointer;
  width: 16px;
  height: 16px;
  position: relative;
}

.tooltip {
  display: none;
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  z-index: 10;
}

.infoIcon:hover .tooltip {
  display: block;
}

.headerTooltipContainer {
  position: absolute;
  display: none;
  border-radius: 4px;
  z-index: 10;
  bottom: 20%;
}

.tooltipWrapper {
  position: relative;
}

.tooltipWrapper:hover .headerTooltipContainer {
  display: block;
}

.greyLine {
  width: 100%;
  height: 1px;
  background-color: grey;
  margin-top: 8px;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  border-radius: 10px;
  color: white;
  width: 100%;
  /* flex: 1; */
}

.flexContainer {
  display: flex;
  width: 100%;
  gap: 20px;
}

.overallSection, .actionItemsSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  border-radius: 8px;
  background: #FFFFFF0D;
  border: 1px solid #FFFFFF1A;
  position: relative;
}

.SLAActionItemsSection {
  display: flex;
  flex-grow: 1;
}

.thirdLineActionItems {
  display: flex;
  flex: 2;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  border-radius: 8px;
  position: relative;
}

.overallSection {
  flex: 1;
}

.actionItemsSection {
  flex: 2;
}

.overallHeader, .actionItemsHeader, .breakdownByOpusHeader {
  color: #FFFFFF;
  margin-bottom: 10px;
  font-family: 'Museo Sans';
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.16px;
  text-align: left;
}

.infoLabel {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 8px;
  font-family: 'Museo Sans';
  font-size: 11px;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0.13px;
  text-align: left;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 4px;
}

.greyDot {
  background: #365F74;
}

.greenDot {
  background: #00FF5D;
}

.yellowDot {
  background: #f1c40f;
}

.redDot {
  background: #DA2228;
}

.overallCircle {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6%;
  align-self: center;
}

.percentage p {
  color: #FFFFFF;
  font-size: 24px;
  font-weight: bold;
  margin: 0;
}

.percentage span {
  font-size: 14px;
  color: #1abc9c;
}

.actionItems {
  display: grid;
  gap: 16px;
  width: 100%;
}

.requirementAction {
  grid-template-columns: repeat(2, 1fr);
}

.SLAActionItems {
  display: flex;
  gap: 16px;
  width: 100%;
}

.scheduleAction {
  grid-template-columns: repeat(3, 1fr);
}

.ACAAction {
  grid-template-columns: repeat(3, 1fr);
}

.auditAction {
  grid-template-columns: repeat(5, 1fr);
}

.actionItem {
  display: flex;
  flex: 1 1 0;
  align-items: center;
  background: #FFFFFF1A;
  border: 1px solid #FFFFFF1A;
  padding: 3px;
  border-radius: 8px;
  border: 1px;
}

.actionItem > div:nth-child(2) {
  display: flex;
  flex-direction: column;
}

.actionItem p {
  color: #FFFFFF;
  font-family: 'Museo Sans';
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0.13px;
  text-align: left;
  opacity: 0.6;
  padding: 0px;
  margin: 5px;
}

.actionItem span {
  color: #FFFFFF;
  margin-top: 8px;
  font-family: 'Museo Sans';
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.194px;
  text-align: left;
  padding: 0px;
  margin: 5px;
}

.bar {
  height: 90%;
  width: 5px;
  border-radius: 9px;
  margin-left: 4px;
}

.overdue .bar { background: #DA2228; }
.notAssigned .bar { background: #5D7083; }
.inProgress .bar { background: #FDB924; }
.closed .bar { background: #0B882E; }
.planned .bar { background: #005CB9; }
.conducted .bar { background: #0B882E; }
.unplanned .bar { background: #C2C2C2; }
.cancelled .bar { background: #FF8A00; }
.deferred .bar { background: #DA2228; }

.breakdownByOpusSection {
  max-height: 380px;
  margin-top: 20px;
  background: #FFFFFF0D;
  border: 1px solid #FFFFFF1A;
  border-radius: 8px;
  padding: 8px;
  overflow-y: auto;
}

.breakdownByOpus {
  margin-top: 20px;
}

.riskSLATable tr {
  background: linear-gradient(180deg, rgba(6, 25, 25, 0.328579) 0%, rgba(0, 0, 0, 0.6) 100%)
}

.riskSLATable td {
  background: transparent;
}

.tableStripe {
  background: #FFFFFF0D;
  border-style: solid;
  border-radius: 5px;
  border-radius: 10px;
  border-width: 3px;
}

.greenText {
  color: #00A19C;
}

.underlinedText {
  text-decoration: underline;
}

.rightAlign {
  text-align: right;
}

.noResult {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  padding-top: 10%;
  color: white;
}
  
.noResult h2 {
color: white;
font-family: 'Museo Sans';
margin-top: 10px;
font-size: 14px;
font-weight: 300;
line-height: 20px;
letter-spacing: 0.194px;
text-align: left;
}


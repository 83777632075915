.sectionContainer {
    margin-top: 16px;
}

.sectionTitle {
    margin-bottom: 8px;
}

.sectionTitle span {
    color: #E5E5E5;
    font-feature-settings: 'clig' off, 'liga' off;
    /* Heading/MS_Subtitle_14px_Bold */
    font-family: 'Museo Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.194px;
}

.timeStamp {
    display: flex;
    align-items: center;
    margin-top: 18px;
    font-family: Museo Sans;
    font-size: 11px;
    font-weight: 300;
    line-height: 15px;
    letter-spacing: 0.1388888955116272px;
    text-align: left;
    color: #ffffff5d;
}

.cargoValue span:nth-child(1) {
    color: #FFF;
    font-family: 'Museo Sans', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.cargoValue span:nth-child(2) {
    color: #FFF;
    font-family: 'Museo Sans', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 17px; /* 141.667% */
}